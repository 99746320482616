import React from "react";

import { graphql } from "gatsby";

import styled from "@emotion/styled";

import CalculatorPage from "../../../../components/pages/Pellets/CalculatorPage";
import PelletsDataProvider from "../../../../providers/PelletsDataProvider";
import { takeWhile } from "../../utils";

//Add some bottom padding because of the missing Footer:
const Container = styled.div`
    padding-bottom: 5em;
`;

const PelletsWebView = ({
    data: {
        page: { elements, ...page },
    },
}) => {
    return (
        <PelletsDataProvider>
            <Container>
                <CalculatorPage
                    isWebview={true}
                    pageData={{
                        ...page,
                        elements: takeWhile(
                            (a) => a.type !== "calculatorWithOutput",
                            elements
                        ),
                    }}
                />
            </Container>
        </PelletsDataProvider>
    );
};

export default PelletsWebView;

export const query = graphql`
    query {
        page: otherPagesYaml(slug: { eq: "holzpellets" }) {
            ...Page
            disabled
            elements {
                ...EntireElement
            }
        }
    }
`;
